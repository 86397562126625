<div class="row">
  <div class="col-md-6">
    <form [formGroup]="client"  (submit)="onSubmit($event)">
      <div class="form-group">
        <label for="exampleInputEmail1">Client Agent Name</label>
        <input type="text" class="form-control" name = "agentName" formControlName="agentName" id="exampleInputEmail1"  placeholder="Enter Client Agent Name">
        <small class="text-danger"
        *ngIf="client.get('agentName').hasError('required')">
            This field is required.
         </small>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Agent's Company Name</label>
        <input type="text" class="form-control" name = "company" formControlName="company" id="exampleInputPassword1" placeholder="Enter Agent's Company Name">
        <small class="text-danger"
        *ngIf="client.get('company').hasError('required')">
            This field is required.
         </small>
         
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Remarks</label>
        <textarea cols="10" rows="5" class="form-control" name = "remarks" formControlName="remarks"></textarea>
        <!-- <input type="text" class="form-control" name = "total" formControlName="total" id="exampleInputEmail1"  placeholder="Enter Total"> -->
      </div>
      <input type="submit" value="Submit" *ngIf = "!activatedRoute.snapshot.queryParams.id" [disabled]="client.invalid" class="btn btn-primary">
      <input type="submit" value="Update" *ngIf = "activatedRoute.snapshot.queryParams.id" [disabled]="client.invalid" class="btn btn-primary">
    </form>
  </div>
</div>