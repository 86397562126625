<div class="customer-detail">
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="{{client.client_name}}" cardClass ="customer-detail-header" options ={{options}}>
        <div class="row">
          <div class="col-lg-4">
            <h4 class="h6"> Client Code</h4>
            <p>{{client.client_code}}</p>
          </div>
          <div class="col-lg-4">
            <h4 class="h6"> Client Name</h4>
            <p>{{client.client_name}}</p>
          </div>
          <div class="col-lg-4">
            <h4 class="h6"> Client Profile</h4>
            <p>{{client.client_profile}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <h4 class="h6"> Client Agent</h4>
            <p>{{client.agent}}</p>
          </div>
          <div class="col-lg-4">
            <h4 class="h6"> Client Support Company</h4>
            <p>{{client.client_support_company}}</p>
          </div>
          <div class="col-lg-4">
            <h4 class="h6"> Company Agent</h4>
            <p>{{client.company_agent_name}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <h4 class="h6"> Total</h4>
            <p>{{client.total}}</p>
          </div>
        </div>
        <div class="row">
         
          <div class="col-lg-12">
            <h4 class="h6"> Remarks</h4>
            <p [innerHtml]="client.remarks| safe: 'textareaFormat'"></p>
          </div>
        </div>
      </app-card>
   </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle=" {{client.client_name}} Payment Detail" cardClass ="customer-payment-detail-header">
        <div class="latest-update-box" *ngFor = "let payment of payments let i=index">
          <div class="row p-t-30 p-b-30">
            <div class="col-auto text-right update-meta">
              <p class="text-muted m-b-0 d-inline-flex">{{payment.payment_received_date |date: 'longDate'}}</p>
              <i class="feather icon-calendar payment-calender update-icon"></i>
            </div>
            <div class="col">
                <h6>{{payment.payment_received}} $ Received</h6>
              <p *ngIf = "payment.next_payment_due > 0" class="text-muted m-b-0">Next Payment Due is {{payment.next_payment_due}} $ on {{payment.next_payment_due_date |date: 'longDate'}} </p>
              <p *ngIf = "payment.next_payment_due == 0" class="text-muted m-b-0">No Due Payemnt</p>
            </div>
          </div>
          
        </div>
      </app-card>
    </div>
    <div class="col-sm-12">
      <app-card cardTitle=" {{client.client_name}} Payment Made For Company" cardClass ="customer-payment-detail-header">
        <button type="button" (click)="editEnable()" *ngIf= "companyPayment.length > 0 && !editMode" class="btn btn-primary">Edit</button>
        <br>
        <button type="button" (click)="editEnable()" *ngIf= "companyPayment.length == 0 && !editMode" class="btn btn-primary">Add Company Payment</button>
        <br>
        <div class="table-responsive" *ngIf="!editMode">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>Payments Made</th>
              <th>Payments Made To</th>
              <th>Remarks</th>
             </tr>
            </thead>
            <tbody>
            <tr *ngFor = "let cPayment of companyPayment let i=index">
              <td>{{i + 1}}</td>
              <td>{{cPayment.payment_made}}</td>
              <td>{{cPayment.payment_made_to}}</td>
              <td>{{cPayment.remarks}}</td>
            </tr>
          </tbody>
        </table>
      </div>
        <form [formGroup]="paymentToCom" *ngIf="editMode" (submit)="onSubmit($event)">
          <div formArrayName="payment" > 
            <div class="payment_customer" *ngFor="let quantity of payment.controls let i=index" [formGroupName]="i" >  
              <div  class="form-group">  
                 <label for="exampleInputEmail1">Payments Made</label>
                  <input type="text" name ="payment_made{{i}}" formControlName="payment_made"  class="form-control"> 
                  <input type="hidden" name ="payment_id{{i}}" formControlName="payment_id"  class="form-control"> 
              </div>
               <div  class="form-group">  
                <label for="exampleInputEmail1"> Payments Made To</label>
                 <input type="text" name ="payment_made_to{{i}}" formControlName="payment_made_to"  class="form-control">  
               </div>
               <div class="form-group">
                <label for="exampleInputEmail1">Remarks</label>
                <textarea cols="10" rows="5" class="form-control" name = "remarks{{i}}" formControlName="remarks"></textarea>
                <!-- <input type="text" class="form-control" name = "total" formControlName="total" id="exampleInputEmail1"  placeholder="Enter Total"> -->
              </div>
              <div>  
                <button (click)="removeQuantity(i)" *ngIf = "!quantity.value['payment_id']" class="btn btn-danger">Remove</button>  
                <input type="button" (click)="deleteComanyPayment(quantity,i)"  value="Delete" *ngIf = "quantity.value['payment_id']" class="btn btn-primary">
              </div>  
            </div>
            </div> 
            <br><br>
            <table class="table">  
            <tr>  
              <th colspan="4">Add Payment Made For Company:</th>  
              <th width="150px"><button type="button" (click)="addQuantity()" class="btn btn-primary">Add More</button></th>  
            </tr> 
          </table> 
          <input type="submit" value="Submit"  [disabled]="paymentToCom.invalid" class="btn btn-primary">
       </form>
      </app-card>
    </div>
  </div>
</div>
