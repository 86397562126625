<div class="auth-wrapper">
  <div class="auth-content text-center">
    <div class="card borderless">
      <div class="row align-items-center text-center">
        <div class="col-md-12">
          <div class="card-body">
            <h4 class="mb-3 f-w-400">Signin</h4>
            <form [formGroup]="login"  (submit)="signin($event)">
              <div class="input-group mb-3">
                <input type="text" class="form-control" formControlName="username"  placeholder="Username">
              </div>
              <div class="input-group mb-4">
                <input type="password" class="form-control" formControlName="password" placeholder="Password">
              </div>
              <button type="submit" value="Submit"  [disabled]="login.invalid" class="btn btn-block btn-primary mb-4">Signin</button>
            </form>
            <div class="alert alert-danger" *ngIf = "message" role="alert">
							Username and password are not correct 
						</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
