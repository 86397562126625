import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { NavigationComponent } from './layout/admin/navigation/navigation.component';
import { NavContentComponent } from './layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './shared/full-screen/toggle-full-screen';

/* Menu Items */
import { NavigationItem } from './layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import {  ReactiveFormsModule } from '@angular/forms';
import { ClientModule } from './client/client.module';
import { AppHttpService } from './services/app-http.service';
import { HttpClientModule } from '@angular/common/http';
import { CompanyModule } from './comapny/company.module';
import { CustomerModule } from './customer/customer.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientListModule } from './client-list/client-list.module';
import { CompanyListModule } from './company-list/company-list.module';
import { CustomerListModule } from './customer-list/customer-list.module';
import { CustomerDetailModule } from './customer-detail/customer-detail.module';
import { SafePipe, SafePipeModule } from './services/safe.pipe';
import { AuthSigninModule } from './auth-signin/auth-signin.module';
import { UserProfileModule } from './user-profile/user-profile.module';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    ReactiveFormsModule,
    ClientModule,
    HttpClientModule,
    CompanyModule,
    CustomerModule,
    NgbModule,
    ClientListModule,
    CompanyListModule,
    CustomerListModule,
    CustomerDetailModule,
    SafePipeModule ,//safe module
    AuthSigninModule,
    UserProfileModule
  ],
  providers: [NavigationItem,AppHttpService],
  bootstrap: [AppComponent]
  
})

export class AppModule { }
