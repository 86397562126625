import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerDetailComponent } from './customer-detail.component';
import {SharedModule} from '../shared/shared.module';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {  ReactiveFormsModule } from '@angular/forms';
import { AppHttpService } from '../services/app-http.service';
import { HttpClientModule } from '@angular/common/http';
import { SafePipeModule } from '../services/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    HttpClientModule,
    SafePipeModule
  ],
  providers: [AppHttpService],
  declarations: [CustomerDetailComponent],
  
})
export class CustomerDetailModule { }
