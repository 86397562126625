import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        classes: 'nav-item',
        icon: 'feather icon-home'
      },
      {
        id: 'agent',
        title: 'Agent',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'client_agent',
            title: 'Client Agent',
            type: 'item',
            url: '/client-list',
            target: false
          },
          {
            id: 'company_agent',
            title: 'Company Agent',
            type: 'item',
            url: '/company-list',
            target: false
          },
        ]
      },
      {
        id: 'customer',
        title: 'Customer',
        type: 'item',
        url: '/customer-list',
        classes: 'nav-item',
        icon: 'feather icon-user'
      },
      {
        id: 'userProfile',
        title: 'Update Password',
        type: 'item',
        url: '/user-profile',
        classes: 'nav-item',
        icon: 'feather icon-lock'
      },
    ]
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
