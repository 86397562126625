<div class="row">
  <div class="col-md-12">
    <app-card cardTitle="Company Agent List" moveToPage="company" blockClass="table-border-style">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Company Agent Name</th>
            <th>Company</th>
            <!-- <th>Status</th> -->
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor = "let cAgent of clientsAgent | paginate: { itemsPerPage: 10, currentPage: p,totalItems: count } let i=index">
            <td>{{cAgent.id}}</td>
            <td>{{cAgent.company_agent_name}}</td>
            <td>{{cAgent.name}}</td>
            <!-- <td><select class="form-control" #mySelect (change) = "updateStatus(mySelect.value,$event)">
                <option value = "1">Active</option>
                <option value = "0">InActive</option>
            </select></td> -->
            <td><button type="button" (click) = "edit(cAgent.id)"  class="btn  btn-icon" >
              <i class="feather icon-edit"></i>
            </button></td>
         
          </tbody>
        </table>
        <pagination-controls
        *ngIf = "clientsAgent.length != 0"
        previousLabel="Prev"
        nextLabel="Next"
        [responsive]="true"
        (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </app-card>
  </div>
</div>