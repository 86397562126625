import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppHttpService } from '../services/app-http.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  client: FormGroup; 
  constructor(private fb: FormBuilder,private apiService: AppHttpService,public router: Router,public activatedRoute: ActivatedRoute) { 

   
  }

  ngOnInit() {
    let self =this;
    if(self.activatedRoute.snapshot.queryParams.id){
      self.getAgentBydId(self.activatedRoute.snapshot.queryParams.id);
    }
    this.client = this.fb.group({
      agentName: ['', Validators.required ],
      company: ['', Validators.required ],
      remarks:['']
    });
  }
  getAgentBydId(id){
    let self =this;
    self.apiService.get(`agent/get-agent-by-id?id=${id}`).subscribe(res => {
      let result = res.result[0];
      this.client = this.fb.group({
        agentName: [result.agent, Validators.required ],
        company: [result.company, Validators.required ],
        remarks:[result.remarks]
      });
           console.log(res.result[0]);
    }, err => {
      
    })
  }
  onSubmit(event: Event) {
    event.preventDefault(); // Prevent default form submission
    // Your form submission logic here
    let self = this;
    let data = {}
    if(self.activatedRoute.snapshot.queryParams.id){
      data = {
        ...this.client.value,
        id:+self.activatedRoute.snapshot.queryParams.id
      }
      console.log(data);
    }else{
      data = {
        ...this.client.value,
      }
    }
    self.apiService.post("agent",data).subscribe(res => {
      console.log(res);
      this.router.navigate(["client-list"],{});
    }, err => {
    })
   
  }


}
