<div class="row">
  <div class="col-md-6">
    <form [formGroup]="customer"  (submit)="onSubmit($event)">
      <div class="form-group">
        <label for="exampleInputEmail1">Customer Name</label>
        <input type="text" class="form-control" name = "clientName" formControlName="clientName" id="exampleInputEmail1"  placeholder="Enter Customer Name">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Program Name</label>
        <input type="text" class="form-control" name = "programName" formControlName="programName" id="exampleInputEmail1"  placeholder="Enter Program Name">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Customer Profile</label>
        <input type="text" class="form-control" name = "clientProfile" formControlName="clientProfile" id="exampleInputEmail1"  placeholder="Enter Customer Profile">
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Client Agent</label>
        <select class="form-control" name="clientAgent" formControlName="clientAgent" #mySelect (change) = "getCleintCompany(mySelect.value,$event)">
          <option  *ngFor="let result of clientAgent; let i =index;" [value]="result.id">{{result.agent}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Client Company Name</label>
        <span> : &nbsp;{{company_client}}</span>
        <input type="hidden" class="form-control" name = "company" formControlName="company" id="exampleInputEmail1" value="{{company_client}}" placeholder="Enter Client Comapny">
      </div>
     
     
      <div class="form-group">
        <label for="exampleInputPassword1">Customer Company Agent</label>
        <select class="form-control" name="companyAgent" formControlName="companyAgent">
          <option  *ngFor="let result of companyAgent; let i =index;" [value]="result.id">{{result.company_agent_name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Total Payment</label>
        <input type="text" class="form-control" name = "total" formControlName="total" id="exampleInputEmail1"  placeholder="Enter Total">
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Status</label>
        <select class="form-control" name="status" formControlName="status">
          <option value="1">Active</option>
          <option value="0">InActive</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Remarks</label>
        <textarea cols="10" rows="5" class="form-control" name = "remarks" formControlName="remarks"></textarea>
        <!-- <input type="text" class="form-control" name = "total" formControlName="total" id="exampleInputEmail1"  placeholder="Enter Total"> -->
      </div>
        <div class="col-md-12">  

              <div formArrayName="payment"> 
              <div *ngFor="let quantity of payment.controls let i=index" [formGroupName]="i" >  
                <div  class="form-group">  
                   <label for="exampleInputEmail1">Payment Received</label>
                    <input type="text" name ="payment_received{{i}}" formControlName="payment_received"  class="form-control"> 
                    <input *ngIf = "activatedRoute.snapshot.queryParams.id" type="hidden" name ="payment_received{{i}}" formControlName="payment_id"  class="form-control"> 
                     
                </div>
                <div  class="form-group">  
                  <label  for="exampleInputEmail1"> Payment Received Date</label>
                  <div class="row">
                    <div class="col-md-9">
                      <input  #paymentReceivedDate="ngbDatepicker" ngbDatepicker  type="text" name ="payment_received_date{{i}}" placeholder="yyyy-mm-dd" formControlName="payment_received_date"  class="form-control"> 
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-outline-secondary bi bi-calendar3"
                    (click)="paymentReceivedDate.toggle()"
                    type="button"><i class="feather icon-calendar"></i></button>
                    </div>
                   
                  </div>
                  
                   <!-- <input class="form-control" placeholder="yyyy-mm-dd" formControlName="payment_received_date" name="payment_received_date{{i}}" ngbDatepicker #d="ngbDatepicker"> -->
                   

                 </div>
                 <div  class="form-group">  
                  <label for="exampleInputEmail1"> Payment Mode</label>
                   <input type="text" name ="payment_mode{{i}}" formControlName="payment_mode"  class="form-control">  
                 </div>
                 <div  class="form-group">  
                  <label for="exampleInputEmail1"> Next Payment Due</label>
                   <input type="text" name ="next_payment_due{{i}}" formControlName="next_payment_due"  class="form-control">  
                 </div>
                 <div  class="form-group">  
                  <label for="exampleInputEmail1"> Next Payment Due Date</label>
                  <div class="row">
                    <div class="col-md-9">
                      <input #licenseExpiration="ngbDatepicker" placeholder="yyyy-mm-dd"  type="text" ngbDatepicker name ="next_payment_due_date{{i}}" formControlName="next_payment_due_date"  class="form-control">  
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-outline-secondary bi bi-calendar3"
                   (click)="licenseExpiration.toggle()"
                   type="button"><i class="feather icon-calendar"></i></button>
                    </div>
                  </div>
                
                 </div>
                
                <div>  
                    <button (click)="removeQuantity(i)" *ngIf = "!quantity.value['payment_id']" class="btn btn-danger">Remove</button>  
                    <input type="button" (click)="deleteCustomerPayment(quantity,i)"  value="Delete" *ngIf = "activatedRoute.snapshot.queryParams.id && quantity.value['payment_id']" class="btn btn-primary">
                </div>  
              </div>
              </div>  
              <table class="table table-bordered">  
                <tr>  
                  <th colspan="4">Add Payment Recived Detail:</th>  
                  <th width="150px"><button type="button" (click)="addQuantity()" class="btn btn-primary">Add More</button></th>  
                </tr> 
              </table> 
        </div>
        <input type="submit" value="Submit" *ngIf = "!activatedRoute.snapshot.queryParams.id" [disabled]="customer.invalid" class="btn btn-primary">
        <input type="submit" value="Update" *ngIf = "activatedRoute.snapshot.queryParams.id" [disabled]="customer.invalid" class="btn btn-primary">
    </form>
  </div>
</div>