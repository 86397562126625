import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppHttpService } from '../services/app-http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  clientsAgent= []; 
  public p = 0;
  public count: any;
  constructor(private fb: FormBuilder,private apiService: AppHttpService,public router: Router) { 
   
  }

  ngOnInit() {
    this.getAgentList(0);
  }
  getAgentList(page:any) {
    let self =this;
    self.apiService.get(`agent/get-agent?skip=${page}`).subscribe(res => {
      console.log(res);
      this.clientsAgent = res.result;
      this.count =res.totalRecords[0]['total_record'];
      console.log(this.count);
    }, err => {
    })
  }
  updateStatus(value,event){

  }
  handlePageChange(event:any){
    let self =this;
    this.p = event;
    let page = 0
    if (this.p) {
      page = (this.p - 1) * 10;
    }
    self.apiService.get(`agent/get-agent?skip=${page}`).subscribe(res => {
      console.log(res);
      this.clientsAgent = res.result;
      this.count =res.totalRecords[0]['total_record'];
    }, err => {
    })
  }
  edit(id){
    this.router.navigate(["client"],{ queryParams: {id:id}});
  }

}
