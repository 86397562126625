import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthSigninComponent } from './auth-signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppHttpService } from '../services/app-http.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [AppHttpService],
  declarations: [AuthSigninComponent]
})
export class AuthSigninModule { }
