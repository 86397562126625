<div class="row">
  <div class="col-md-12">
    <app-card cardTitle="Customer List {{statusOfCustomer}}" moveToPage="customer" blockClass="table-border-style">
      <div>
        <button (click) ="download()"  type="button" class="btn  btn-icon" >
        <i class="feather icon-download"></i>
      </button><span (click) ="download()" class="download-cursor">Download Customer Report</span> &nbsp;
      <button (click) ="downloadCompanyReport()"  type="button" class="btn  btn-icon" >
        <i class="feather icon-download"></i>
      </button><span (click) ="downloadCompanyReport()" class="download-cursor">Download Customer Comapny Report</span>
     </div>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Client Code</th>
            <th>Client Name</th>
            <th>Agent Name</th>
            <th>Company</th>
            <th>Company Agent Name</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor = "let client of clients | paginate: { itemsPerPage: 10, currentPage: p,totalItems: count } let i=index">
            <td>{{client.id}}</td>
            <td>{{client.client_code}}</td>
            <td>{{client.client_name}}</td>
            <td>{{client.agent}}</td>
            <td>{{client.client_support_company}}</td>
            <td>{{client.company_agent_name}}</td>
            <td>{{client.total}}</td>
            <td><button (click) = "detail(client.id)" type="button" class="btn  btn-icon" >
              <i class="feather icon-eye"></i>
            </button><button (click) ="edit(client.id)"  type="button" class="btn  btn-icon" >
              <i class="feather icon-edit"></i>
            </button><button (click) ="deleteClient(client.id)"  type="button" class="btn  btn-icon" >
              <i class="feather icon-delete"></i>
            </button></td>
         
          </tbody>
        </table>
        <pagination-controls
        *ngIf = "clients.length != 0"
        previousLabel="Prev"
        nextLabel="Next"
        [responsive]="true"
        (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </app-card>
  </div>
</div>