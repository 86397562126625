import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { AppHttpService } from '../services/app-http.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  client:any; 
  payments: any = [];
  options: boolean = false;
  paymentToCom: FormGroup; 
  companyPayment:any = [];
  editMode: boolean  =false;
  constructor(private fb: FormBuilder,private apiService: AppHttpService,private activatedRoute: ActivatedRoute) { 
   
  }

  ngOnInit() {
    let self =this;
    self.paymentToCom = this.fb.group({
      payment: this.fb.array([])
    });
    if(self.activatedRoute.snapshot.queryParams.id){
      console.log(self.payment);  
      this.getCustomerDetail(self.activatedRoute.snapshot.queryParams.id);
    }
    
  }
  get payment(): FormArray {
    return this.paymentToCom.get('payment') as FormArray;
  }
  addQuantity(){
    this.payment.push(this.fb.group({
      // Define form controls here
      payment_made: ['',Validators.required],
      payment_made_to:['',Validators.required],
      remarks:[''],
      payment_id:[false]
    }));
    console.log(this.payment);
  }
  removeQuantity(i){
    this.payment.removeAt(i);
  }
  editEnable(){
    this.editMode = true;
  }
  getCustomerDetail(id:any) {
    let self =this;
    self.apiService.get(`client/get-client-by-id?id=${id}`).subscribe(res => {
      console.log(res);
      this.client = res.result[0];
      this.payments = res.payment;
      this.companyPayment = res.payment_company;
      if(res.payment_company && res.payment_company.length > 0){
        res.payment_company.forEach(element => {
          self.payment.push(self.fb.group({
            // Define form controls here
            payment_made: [element.payment_made,Validators.required],
            payment_made_to:[element.payment_made_to,Validators.required],
            remarks:[element.remarks],
            payment_id:[element.id]
          }));
        });
      }
    }, err => {
    })
  }
  
  deleteComanyPayment(data,i){
    console.log(data.value.payment_id);
    let self =this;
    self.apiService.get(`client/remove-company-payment?id=${data.value.payment_id}`).subscribe(res => {
      console.log(res);
      this.payment.removeAt(i);
    });
  }
  onSubmit(event){
    let self =this;
    if(this.paymentToCom.value.payment.length > 0){
      console.log(this.paymentToCom.value);
       let body = {
        ...self.paymentToCom.value,
        client_id: +self.activatedRoute.snapshot.queryParams.id,
        company_id:self.client.company_agent
       }
      self.apiService.post("client/add-edit-payment",body).subscribe(res => {
        console.log(res);
        console.log(self.payment.controls.length);
        for (let i=0; i<self.payment.controls.length;i++){
          self.payment.removeAt(i);
        }
        window.location.reload();
        // self.getCustomerDetail(self.activatedRoute.snapshot.queryParams.id);
        // console.log(self.payment);  
      }, err => {
      })
    }
  }

}
