<div class="auth-wrapper update-password">
  <div class="auth-content text-center">
    <div class="card borderless">
      <div class="row align-items-center text-center">
        <div class="col-md-12">
          <div class="card-body">
            <h4 class="mb-3 f-w-400">Update Password</h4>
            <form [formGroup]="login"  (submit)="signin($event)">
              <div class="input-group mb-3">
                <input type="password" class="form-control" formControlName="oldpassword"  placeholder="Old Password">
              </div>
              <div class="input-group mb-4">
                <input type="password" class="form-control" formControlName="password" placeholder="New Password">
              </div>
              <button type="submit" value="Submit"  [disabled]="login.invalid" class="btn btn-block btn-primary mb-4">Update Password</button>
            </form>
            <div *ngIf="pMessage"
            class="alert alert-danger">Old Passwords do not match.</div>

            <div class="alert alert-success"  *ngIf = "message" role="alert">
						{{message}}
						</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
