
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AppHttpService {
    public hostUrl = environment.API_URI;
    constructor(
        public httpClient: HttpClient,
        public router: Router) { }
    
    get(url:any, params: any = {}) {
        let httpOptions = {
            "ngrok-skip-browser-warning":"true"
        };
        return this.httpClient.get<any>(this.hostUrl + url, { params: params, headers: httpOptions }).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(),
            catchError(this.handleError)
        )
    }

    post(url:any, params: any = {}, queryParams: any = {}) {
        let httpOptions = {
            "ngrok-skip-browser-warning":"true"
        };
        return this.httpClient.post<any>(this.hostUrl + url, params, { headers: httpOptions, params: queryParams }).pipe(map(data => {
            if (data) {
                return data
            } else {
                return []
            }
        }), tap(), catchError(this.handleError))
    }

   

    public handleError(error: HttpErrorResponse) {
        return throwError(error.error);
    };
}