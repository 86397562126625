import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppHttpService } from 'src/app/services/app-http.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  login: FormGroup; 
  message = '';
  pMessage =false;
  constructor(private fb: FormBuilder,private apiService: AppHttpService,private authService: AuthService,public router: Router) { }

  ngOnInit() {
    this.login = this.fb.group({
      oldpassword: ['', Validators.required ],
      password: ['', Validators.required ],
    });
  }
  signin(event){
    let self = this;
    let data = {
      ...this.login.value,
    };
    self.apiService.post("update-password",data).subscribe(res => {
      console.log(res);
      self.message =res.message;
      self.pMessage =false;
    }, err => {
      self.message ="";
      self.pMessage = true;
    })
     
  }
}
