import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { AppHttpService } from '../services/app-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  customer: FormGroup; 
  clientAgent: any =[];
  company_client: any;
  companyAgent: any;
  selectedDate: NgbDateStruct;
  public quantities: any[] = []
  maxDate: { year: number; month: number; day: number; };
  minDate: { year: number; month: number; day: number; };
  startDate: { year: number; month: number; day: number; };
  constructor(private fb: FormBuilder,private apiService: AppHttpService,public router: Router,public activatedRoute: ActivatedRoute) { 
   
  }

  ngOnInit() {
    let self = this;
    this.getClientAgent();
    this.getCompanyAgent();
    this.customer = this.fb.group({
      clientName: ['', Validators.required ],
      programName:['', Validators.required ],
      clientAgent: ['', Validators.required ],
      clientProfile:['', Validators.required ],
      company: [''],
      companyAgent: ['', Validators.required ],
      status:['', Validators.required ],
      remarks:[''],
      total:['', Validators.required ],
      payment: this.fb.array([])
    });
    if(self.activatedRoute.snapshot.queryParams.id){
      self.getClientBydId(self.activatedRoute.snapshot.queryParams.id);
    }
  }
  getClientBydId(id){
    let self =this;
    self.apiService.get(`client/get-client-by-id?id=${id}`).subscribe(res => {
      let result = res.result[0];
      this.customer = this.fb.group({
        clientName: [result.client_name, Validators.required ],
        programName:[result.program_name, Validators.required ],
        clientAgent: [result.client_agent, Validators.required ],
        clientProfile:[result.client_profile, Validators.required ],
        company: [result.client_support_company],
        companyAgent: [result.company_agent, Validators.required ],
        status:[result.status, Validators.required ],
        remarks:[result.remarks],
        total:[result.total, Validators.required ],
        payment: this.fb.array([])
      });
      self.company_client = result.client_support_company;
      if(res.payment && res.payment.length > 0){
        res.payment.forEach(element => {
          const dateStringForRdate = element.payment_received_date;
          let payment_received_date  = this.getDateComponents(dateStringForRdate);
          const dateStringForDueDate = element.next_payment_due_date;
          let next_payment_due_date  = this.getDateComponents(dateStringForDueDate);
          console.log(payment_received_date,next_payment_due_date);
          self.payment.push(self.fb.group({
            // Define form controls here
            payment_received: [element.payment_received],
            //payment_received_date: [new FormControl({day: payment_received_date.day, month:payment_received_date.month, year:payment_received_date.year})],
            payment_received_date: [{day: payment_received_date.day, month:payment_received_date.month, year:payment_received_date.year}],
            next_payment_due:[element.next_payment_due],
            next_payment_due_date:[{day: next_payment_due_date.day, month:next_payment_due_date.month, year:next_payment_due_date.year}],
            payment_mode:[element.payment_mode],
            payment_id:[element.id]
          }));
        });
      }
      console.log(self.customer);
           console.log(res.result[0]);
    }, err => {
      
    })
  }
  getClientAgent(){
     let self = this;
    self.apiService.get("agent/get-agent").subscribe(res => {
      console.log(res);
      this.clientAgent = res.result
    }, err => {
    })
  }
  getCompanyAgent(){
    let self = this;
   self.apiService.get("c-agent/get-agent").subscribe(res => {
     console.log(res);
     this.companyAgent = res.result
   }, err => {
   })
 }
  getCleintCompany(value,event){
    this.company_client =this.clientAgent[value-1].company;
  }
  get payment(): FormArray {
    return this.customer.get('payment') as FormArray;
  }
  onSubmit(event: Event) {
    event.preventDefault(); // Prevent default form submission
    // Your form submission logic here
    this.customer.controls.company.setValue(this.company_client);

    console.log(this.customer.value);
     let body;
    
    let self = this;
    if(self.activatedRoute.snapshot.queryParams.id){
      body = {
        ...this.customer.value,
        id:+self.activatedRoute.snapshot.queryParams.id
      }
      console.log(body);
    }else{
      body = {
        ...this.customer.value,
      }
    }
    self.apiService.post("client",body).subscribe(res => {
      console.log(res);
      this.router.navigate(["customer-list"],{});
    }, err => {
    })
  }
  addQuantity(){
    this.quantities.push({payment_received:null,payment_received_date:null,next_payment_due:null,next_payment_due_date:null,payment_mode:null});
    let curretnDate = this.getDateComponents(new Date().toISOString());
    this.payment.push(this.fb.group({
      // Define form controls here
      payment_received: [''],
      payment_received_date: [{
        day: curretnDate.day,
        month: curretnDate.month,
        year: curretnDate.year,
      }],
      next_payment_due:[''],
      next_payment_due_date:[{
        day: curretnDate.day,
        month: curretnDate.month,
        year: curretnDate.year,
      }],
      payment_mode:['']
    }));
    console.log(this.payment);
  }
  removeQuantity(i){
    this.payment.removeAt(i);
  }
  getDateComponents(dateString: string): { day: number, month: number, year: number } {
    
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Adding 1 because getUTCMonth returns 0-based month index
    const year = date.getUTCFullYear();

    return { day, month, year };
  }
  deleteCustomerPayment(data:any,i){
    console.log(data.value.payment_id);
    let self =this;
    self.apiService.get(`client/remove-payment?id=${data.value.payment_id}`).subscribe(res => {
      console.log(res);
      this.payment.removeAt(i);
    });
    
  }

}
