import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { ClientComponent } from './client/client.component';
import { CompanyComponent } from './comapny/company.component';
import { CustomerComponent } from './customer/customer.component';
import { ClientListComponent } from './client-list/client-list.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { AuthGuard } from './auth.guard';
import { AuthSigninComponent } from './auth-signin/auth-signin.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./dashboard/dash-default/dash-default-routing.module').then(module => module.DashDefaultRoutingModule)
        
      },
      {
        path: 'client-list',
        canActivate: [AuthGuard],
        component: ClientListComponent 
      },
      {
        path: 'client',
        canActivate: [AuthGuard],
        component: ClientComponent 
      },
      {
        path: 'company',
        canActivate: [AuthGuard],
        component: CompanyComponent
      },
      {
        path: 'company-list',
        canActivate: [AuthGuard],
        component: CompanyListComponent
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        component: CustomerComponent
      },
      {
        path: 'customer-list',
        canActivate: [AuthGuard],
        component: CustomerListComponent
      },
      {
        path: 'customer-detail',
        canActivate: [AuthGuard],
        component: CustomerDetailComponent
      },
      {
        path: 'user-profile',
        canActivate: [AuthGuard],
        component: UserProfileComponent
      },
      
    ]
  },
  {
    path: 'login',
    component: AuthSigninComponent
  }
 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
