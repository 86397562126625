import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppHttpService } from '../services/app-http.service';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  clients= []; 
  public hostUrl = environment.API_URI;
  public p = 0;
  public count: any;
  public status= undefined;
  public statusOfCustomer = '';
  private navigationSubscription: Subscription;
  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,public httpClient: HttpClient,private apiService: AppHttpService,private router: Router) { 
   
  }

  ngOnInit() {
    let self =this;
    if(self.activatedRoute.snapshot.queryParams.status){
      self.status = self.activatedRoute.snapshot.queryParams.status;
      if(self.status=='0'){
        self.statusOfCustomer = " Inactive"
      }else{
        self.statusOfCustomer = " Active"
      }
    }
    this.getAgentList(0);
    this.navigationSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log('Current Route:', event);
      if(event.url == "/customer-list"){
        self.clients = [];
        self.status = undefined;
        self.p = 0;
        self.statusOfCustomer ='';
        self.getAgentList(0);
      }
      // You can perform actions based on the current route here
    });
  }
  ngOnDestroy(): void {
    // Unsubscribe from the subscription when the component is destroyed
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  getAgentList(page:any) {
    let self =this;
    self.apiService.get(`client/get-clients?skip=${page}&status=${self.status}`).subscribe(res => {
      this.clients = res.result;
      this.count =res.totalRecords[0]['total_record'];
    }, err => {
    })
  }
  handlePageChange(event:any){
    let self =this;
    this.p = event;
    let page = 0
    if (this.p) {
      page = (this.p - 1) * 10;
    }
    self.apiService.get(`client/get-clients?skip=${page}&status=${self.status}`).subscribe(res => {
       this.clients = res.result;
      this.count =res.totalRecords[0]['total_record'];
    }, err => {
    })
  }
  detail(id){
    let params = {
      id:id
    }
    console.log(params);
    this.router.navigate(['/customer-detail'],{ queryParams:params});
  }
  edit(id){
    this.router.navigate(["customer"],{ queryParams: {id:id}});
  }
  downloadCompanyReport(){
    let self =this;
    
    self.httpClient.get(self.hostUrl+`downloadCompanyPaymentExcel`, { responseType: 'blob' }).subscribe({
      next: (blob: Blob) => {
        // Create a Blob URL
        const url = URL.createObjectURL(blob);

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;

        // Set the filename for the download
        a.download = 'customerCompanyPayment.xlsx';

        // Append the anchor element to the document
        document.body.appendChild(a);

        // Programmatically click the anchor element to initiate the download
        a.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(a);

        // Revoke the Blob URL to release resources
        URL.revokeObjectURL(url);
      },
      error: (error) => {
        console.error('Error downloading the Excel file:', error);
      }
    });
  }
  download(){
    let self =this;
    
    self.httpClient.get(self.hostUrl+`downloadExcel`, { responseType: 'blob' }).subscribe({
      next: (blob: Blob) => {
        // Create a Blob URL
        const url = URL.createObjectURL(blob);

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;

        // Set the filename for the download
        a.download = 'customer.xlsx';

        // Append the anchor element to the document
        document.body.appendChild(a);

        // Programmatically click the anchor element to initiate the download
        a.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(a);

        // Revoke the Blob URL to release resources
        URL.revokeObjectURL(url);
      },
      error: (error) => {
        console.error('Error downloading the Excel file:', error);
      }
    });
  }
  deleteClient(id:any){
    let self =this;
    self.apiService.get(`client/remove-client?id=${id}`).subscribe(res => {
      console.log(res);
      console.log(self.p);
      let page = 0;
      if (self.p) {
        page = (this.p - 1) * 10;
      }
      self.getAgentList(page);
    }, err => {
    })
  }

}
