// auth.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated(): boolean {
    // Implement your authentication logic here
    // Example: Check if the user is logged in
    return !!localStorage.getItem('token'); // Check if token exists in local storage
  }
  logOut():boolean {
    localStorage.removeItem('token');
    return true;
  }
}
