import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppHttpService } from 'src/app/services/app-http.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signin.component.html',
  styleUrls: ['./auth-signin.component.scss']
})
export class AuthSigninComponent implements OnInit {
  login: FormGroup; 
  message = false;
  constructor(private fb: FormBuilder,private apiService: AppHttpService,private authService: AuthService,public router: Router) { }

  ngOnInit() {
    this.login = this.fb.group({
      username: ['', Validators.required ],
      password: ['', Validators.required ],
    });
  }
  signin(event){
    let self = this;
    let data = {
      ...this.login.value,
    };
    self.apiService.post("login",data).subscribe(res => {
      console.log(res);
      localStorage.setItem('token', 'true');
      this.router.navigate(["dashboard"],{});
    }, err => {

      self.message = true;
    })
     
  }
}
